import React from 'react'
import Helmet from 'react-helmet'
import {getUrlParameterByName} from '../util/url'

const Cheq = () => {
    const inner = {
        innerHTML:
            typeof window === `undefined`
                ? ``
                : `
            const cq = document.createElement('script')
            cq.src = 'https://euob.verify.startingpage.com/sxp/i/a6067631be43f7c5c553d38f62651803.js'
            cq.async = true
            cq.className = 'ct_clicktrue_57460'
            cq.dataset.ch = 's1sp'
            cq.dataset.uvid = "${getUrlParameterByName('bds_s') || 'unspecified'}"
            window.onCheqResponse = (i, rid) => window.spCqrid = rid
            document.head.append(cq)
        `,
    }
    return (
        <>
            <Helmet script={[inner]} />
            <noscript>
                <iframe
                    title="cheq"
                    src="https://obseu.verify.startingpage.com/ns/a6067631be43f7c5c553d38f62651803.html?ch=s1sp"
                    width="0"
                    height="0"
                    style={{display: 'none'}}
                />
            </noscript>
        </>
    )
}

export default Cheq
