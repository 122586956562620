import {startpage as DPL} from '@s1/dpl'
import {getStartpageBaseOrigin} from './url'

const {
    events: {startpage: spDpl},
} = DPL // export events

export const EVENTS = spDpl

export const payloadAllowlist = [
    'bc',
    'be',
    'bi',
    'bs',
    'cat',
    'cqrid',
    'ua',
    'surveyConnect',
    'surveyVpn',
    'surveyChangeUa',
    'captchaResponse',
    'url',
    // captcha-block form fields
    'surveyAnon',
    'surveyNetwork',
    'surveyUa',
    'surveyBrowser',
    'surveyIp',
]

const baseOrigin = getStartpageBaseOrigin()

export const logDpl = async (event = EVENTS.StartpagePageLoad, payload = {}) => {
    return event.log(payload, {
        apiOrigin: `${baseOrigin}/sp`,
        payloadAllowlist,
    })
}
