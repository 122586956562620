import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core'
import {themes} from '../util/theme'

const buttonStyles = css`
    text-decoration: none;
    button {
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
        border-radius: 2rem;
        display: inline-block;
        font-family: inherit;
        font-weight: 400;
        letter-spacing: 0.02rem;
        line-height: 1.2;
        transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
        margin-right: 1rem;
        margin-bottom: 1rem;
        background-color: ${themes.light.brandBlue};
        border: none;
        color: ${themes.light.heroTextColor};
        padding: 0.8rem 2rem;
        &:hover {
            cursor: pointer;
            background-color: #a3b1ff;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
`

const disabled = css`
    pointer-events: none;
    button {
        background-color: #f4f5fb;
        color: #ced1dd;
    }
`

const Button = ({text, link, onClick, type, isDisabled, ariaLabel, overrideStyles}) => {
    return (
        <a
            css={
                isDisabled
                    ? css`
                          ${buttonStyles};
                          ${disabled};
                          ${overrideStyles};
                      `
                    : css`
                          ${buttonStyles};
                          ${overrideStyles};
                      `
            }
            href={link}
            aria-label={ariaLabel}
        >
            <button className="conversion-button" disabled={isDisabled} onClick={onClick} type={type}>
                {text}
            </button>
        </a>
    )
}

Button.propTypes = {
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    ariaLabel: PropTypes.string,
    overrideStyles: PropTypes.string,
}

Button.defaultProps = {
    isDisabled: false,
    link: `https://www.startpage.com`,
    // eslint-disable-next-line no-console
    onClick: () => console.log('button clicked'),
    text: `Yes, install`,
    ariaLabel: `Yes, install`,
    overrideStyles: ``,
    type: `submit`,
}

export default Button
