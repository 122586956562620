import React, {useState, useEffect, useCallback, useRef} from 'react'
import PropTypes from 'prop-types'

const CountDownTimer = ({time, onTimerComplete}) => {
    const padTime = (val) => {
        const valString = `${val || '00'}`
        if (valString.length < 2) {
            return `0${valString}`
        }
        return valString
    }

    const humanReadableTime = (sec) => {
        return `${padTime(parseInt(sec / 60))}:${padTime(sec % 60)}`
    }

    const [timeLeft, setTimeLeft] = useState(time * 60) // convert to minutes

    const tick = useRef(null)
    const countDown = useCallback(() => {
        tick.current = setTimeout(() => {
            if (timeLeft <= 0) {
                clearTimeout(tick.current)
                return
            }
            setTimeLeft((currentTimeLeft) => {
                const decrementedTime = --currentTimeLeft
                // counter is finished
                if (decrementedTime === 0) {
                    clearTimeout(tick.current)
                    // this setTimeout solves error with updating two components at once
                    setTimeout(() => onTimerComplete())
                    return 0
                }
                countDown()
                return decrementedTime
            })
        }, 1000)
    }, [onTimerComplete, tick, timeLeft])

    useEffect(() => {
        // initiate countdown
        countDown()
        // cleanup countdown
        return () => clearTimeout(tick.current)
    }, [time, countDown, tick])

    return <span>{humanReadableTime(timeLeft)}</span>
}

CountDownTimer.propTypes = {
    time: PropTypes.number,
    onTimerComplete: PropTypes.func,
}

CountDownTimer.defaultProps = {
    time: 2, // min
    onTimerComplete: () => console.log('timer complete'),
}

export default CountDownTimer
