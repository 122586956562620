/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, camelcase, react/no-danger */
import React, {useEffect, useState, useCallback} from 'react'
import {css} from '@emotion/core'
import {getThemeStyles, themes} from '../util/theme'
import {createMarkup} from '../graphql/datoCmsDataFactory'
import {getLanguageCode} from '../util/locale'
import {getUserAgent} from '../util/userAgent'
import {
    DEFAULT_CAPTCHA_TIME,
    getLocalizedCaptchaSharedText,
    getLocalizedCaptchaPageText,
    getCaptchaData,
    getCaptchaBaseDomain,
    validateCaptcha,
    requestNewCaptcha,
} from '../util/captchaService'
import {logDpl, EVENTS} from '../util/dpl'
import {getUrlParameterByName} from '../util/url'
import {localStore} from '../util/storageProxy'

import Layout from '../components/Layout'
import Button from '../components/Button'
import CountDownTimer from '../components/CountDownTimer'
import Cheq from '../components/Cq'

const TEXTAREA_MAX_LENGTH = 250;
const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const CaptchaBlockedPage = ({languageCode}) => {
    const lang = languageCode || getLanguageCode()

    const {
        captchaErrorText,
        captchaInvalidText,
        captchaExpiredText,
        captchaMaxTriesText,
        timeRemainingText,
        submitButtonText,
    } = getLocalizedCaptchaSharedText(lang)

    const {
        pageTitle,
        introText,
        middleText,
        lastText,
        promptText,
        survey1: {prompt: prompt1, choices: choices1},
        emailLabel,
        descriptionLabel,
        captchaLabel,
        captchaInputLabel,
        reloadPrompt1,
        reloadPrompt2,
        reloadPrompt3,
        invalidEmailText,
        captchaSuccessHeading,
        captchaSuccessDescription
    } = getLocalizedCaptchaPageText(lang, 'blocked')
    // Are you using a VPN, Tor, or other anonymous proxies?
    const [vpnText, torText, proxyText, sharedNetworkText] = choices1
    const [email, setEmail] = useState('')
    const [description, setDescription] = useState('')

    // just use default for captcha page, no need to get fancy ;)
    const [theme, setTheme] = useState('light')
    const [themeStyles, setThemeStyles] = useState({})
    const [surveyData, setSurveyData] = useState({})
    const [captchaData, setCaptchaData] = useState(null)
    const [captchaInputText, setCaptchaInputText] = useState(``)
    const [captchaTimerComplete, setCaptchaTimerComplete] = useState(false)
    const [showCaptchaSuccessMessage, setCaptchaSuccess] = useState(false)
    const [showInvalidCaptchaAttemptMessage, setShowInvalidCaptchaAttemptMessage] = useState(false)
    const [showSystemError, setShowSystemError] = useState(false)
    const [showMaxTriesError, setShowMaxTriesError] = useState(false)
    const [showExpiredHmacTokenError, setShowExpiredHmacTokenError] = useState(false)
    const [showTextAreaMaxLengthError, setShowTextAreaMaxLengthError] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const resetCaptcha = () => {
        setCaptchaInputText(``)
        setCaptchaTimerComplete(true)
        setCaptchaTimerComplete(false)
        setShowInvalidCaptchaAttemptMessage(false)
    }

    const fetchCaptchaData = useCallback(async (e) => {
        if (e) e.preventDefault()
        try {
            const bdsToken = getUrlParameterByName('bds_t') || ''
            const bdsEip = getUrlParameterByName('be') || ''
            if (bdsToken && bdsEip) {
                const data = await getCaptchaData(bdsEip, bdsToken)
                if (data.captcha_image === undefined) {
                    setShowSystemError(true)
                    return
                }
                setCaptchaData(data)
                resetCaptcha()
            } else {
                // force the catch block below to fire
                throw new Error('invalid captcha params')
            }
        } catch (e) {
            const {status} = e
            if (status === 401) {
                setShowExpiredHmacTokenError(true)
            } else {
                setShowSystemError(true)
            }
        }
    }, [])

    const onReloadClick = (e) => {
        const {captcha_image: captchaImageHash} = captchaData
        requestNewCaptcha(captchaInputText, captchaImageHash).then((resp) => {
            const {redirectUrl} = resp
            const updatedImageHash = getUrlParameterByName('image', redirectUrl)
            setCaptchaData({
                ...captchaData,
                ...{
                    captcha_image: updatedImageHash,
                },
            })
            resetCaptcha()
        })
    }

    useEffect(() => {
        const theme = 'light'
        const themeStyles = getThemeStyles(theme)
        setTheme(theme)
        setThemeStyles(themeStyles)
        if (!captchaData) {
            fetchCaptchaData()
            logDpl(EVENTS.StartpageCaptchaVisit, {
                cat: getUrlParameterByName('cat') || '',
                bc: getUrlParameterByName('bc') || '',
                be: getUrlParameterByName('be') || '',
                bi: getUrlParameterByName('bi') || '',
                bs: getUrlParameterByName('bds_s') || '',
                ua: getUserAgent() || '',
            })
        }
        const previousCaptchaCompleted = localStore.getItem('captcha-completed');
        if (previousCaptchaCompleted) {
            const currentDate = new Date();
            const completedDate = new Date(JSON.parse(previousCaptchaCompleted));
            if (completedDate >= currentDate) {
                setCaptchaSuccess(true);
            }
        }
    }, [captchaData, fetchCaptchaData])

    const onCaptchaSubmit = (e) => {
        e.preventDefault()
        const {captcha_image: captchaImageHash} = captchaData
        validateCaptcha(captchaInputText, captchaImageHash, 'blocked', {
            email: email,
            description: description,
            cqrid: window.spCqrid,
        }).then((resp) => {
            const {surveyAnon, surveyNetwork, surveyUa, surveyBrowser, surveyIp} = surveyData
            const {redirectUrl, status_type} = resp
            logDpl(EVENTS.StartpageCaptchaBlockSubmit, {
                captchaResponse: status_type,
                cat: getUrlParameterByName('cat') || '',
                bc: getUrlParameterByName('bc') || '',
                be: getUrlParameterByName('be') || '',
                bi: getUrlParameterByName('bi') || '',
                bs: getUrlParameterByName('bds_s') || '',
                ua: getUserAgent() || '',
                surveyAnon: surveyAnon || '',
                surveyNetwork: surveyNetwork || '',
                surveyUa: surveyUa || '',
                surveyBrowser: surveyBrowser || '',
                surveyIp: surveyIp || '',
                cqrid: window.spCqrid || '',
            }).then(() => {
                let updatedImageHash
                switch (status_type) {
                    case 'attempt_success':
                        setCaptchaSuccess(true)
                        const currentDate = new Date();
                        const futureDate = new Date(currentDate);
                        futureDate.setDate(futureDate.getDate() + 1);
                        localStore.setItem("captcha-completed", JSON.stringify(futureDate));
                        window.scrollTo(0, 0);
                        break
                    case 'failed':
                        updatedImageHash = getUrlParameterByName('image', redirectUrl)
                        setCaptchaData({
                            ...captchaData,
                            ...{
                                captcha_image: updatedImageHash,
                            },
                        })
                        setCaptchaInputText(``)
                        setShowInvalidCaptchaAttemptMessage(true)
                        break
                    case 'empty':
                        updatedImageHash = getUrlParameterByName('image', redirectUrl)
                        setCaptchaData({
                            ...captchaData,
                            ...{
                                captcha_image: updatedImageHash,
                            },
                        })
                        setCaptchaInputText(``)
                        setShowInvalidCaptchaAttemptMessage(true)
                        break
                    case 'timeout':
                        setCaptchaTimerComplete(true)
                        break
                    case 'attempt_violation_error':
                        setShowMaxTriesError(true)
                        break
                    default:
                        setCaptchaInputText(``)
                        setShowInvalidCaptchaAttemptMessage(true)
                }
            })
        })
    }

    const onSurveyInputChange = (e) => {
        const currentSelection = {}
        const {name, value} = e.target
        currentSelection[name] = value
        setSurveyData({...surveyData, ...currentSelection})
    }

    const onCaptchaInputChange = (e) => {
        const {value} = e.target
        setCaptchaInputText(value)
        if (value) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }

    const onEmailInputChange = (e) => {
        const emailText = e.target.value;
        setEmail(emailText);
        if (emailText && !EMAIL_REGEX.test(emailText)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }

    }

    return (
        <>
        <Cheq />
        <Layout
            title="Startpage Blocked"
            description={`Startpage's blocked page`}
            lang={lang}
            canonicalPath="/"
            themeStyles={themeStyles}
            theme={theme}
            bannerTitle={pageTitle}
            hidePrivacyDropdown
        >
            <section
                css={css`
                    background: ${themeStyles.lighterBackgroundColor};
                    p,
                    b,
                    li,
                    label,
                    span {
                        color: ${themeStyles.primaryTextColor};
                    }
                    padding: 7rem 1rem 8rem;
                    min-height: 100%;
                `}
            >
                <div
                    css={css`
                        max-width: 642px;
                        margin: auto;
                        p {
                            margin-bottom: 2rem;
                        }
                        a {
                            color: ${themes.light.brandBlue};
                        }
                    `}
                >
                    {showCaptchaSuccessMessage && (
                        <div
                            className="captcha-success-msg"
                            css={css`
                                color: ${themeStyles.primaryTextColor};
                                h3 {
                                    font-weight: 600;
                                    font-size: 22px;
                                    line-height: 30px;
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 24px;
                                }
                                div {
                                    font-weight: 400;
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                                
                            `}
                        >
                            <h3>
                                {captchaSuccessHeading}
                            </h3>
                            <div>
                                {captchaSuccessDescription}
                            </div>
                        </div>
                    )}
                    {showSystemError && (
                        <div
                            className="system-error-msg"
                            dangerouslySetInnerHTML={createMarkup(captchaErrorText)}
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                    {showExpiredHmacTokenError && (
                        <div
                            className="expired-error-msg"
                            dangerouslySetInnerHTML={createMarkup(captchaMaxTriesText)}
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                    {showMaxTriesError && (
                        <div
                            className="max-tries-error-msg"
                            dangerouslySetInnerHTML={createMarkup(captchaMaxTriesText)}
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                </div>
                <div>
                    {!showSystemError &&
                        !showExpiredHmacTokenError &&
                        !showMaxTriesError &&
                        !showCaptchaSuccessMessage && (
                            <div
                                css={css`
                                    max-width: 642px;
                                    margin: auto auto auto 15rem;
                                    font-size: 18px;
                                    line-height: 24px;
                                    p {
                                        margin-bottom: 28px;
                                    }

                                    @media (max-width: 1000px) {
                                        margin: auto;
                                    }
                                `}
                            >
                                <section className="hero">
                                    <p>{introText}</p>
                                    <p>{middleText}</p>
                                    <p><b>{lastText}</b></p>
                                    <p>
                                        {promptText}
                                    </p>
                                </section>
                                <section className="user-input">
                                    <form
                                        onSubmit={onCaptchaSubmit}
                                        css={css`
                                            .survey-section {
                                                margin-bottom: 1rem;
                                                display: flex;
                                                flex-direction: column;
                                            }
                                            .prompt {
                                                margin-bottom: 8px;
                                            }
                                            .radio-btn,
                                            .connect {
                                                margin: 3px 0;
                                            }
                                            label {
                                                &:hover {
                                                    cursor: pointer;
                                                }
                                            }
                                            .vpn-select {
                                                margin-left: 0.5rem;
                                            }
                                            .conversion-button {
                                                margin-top: 1rem;
                                            }
                                            input {
                                                margin-right: 6px;

                                                &:focus {
                                                    border: solid 1px #6677FB;
                                                }
                                            }
                                            textarea {

                                                &:focus {
                                                    border: solid 1px #6677FB;
                                                }
                                            }
                                        `}
                                    >
                                        <div
                                            type="1"
                                            css={css`
                                                margin-bottom: 2rem;
                                            `}
                                        >
                                            <div className="survey-section">
                                                <div className="prompt">{prompt1}</div>
                                                <label className="radio-btn" htmlFor="vpn">
                                                    <input
                                                        type="checkbox"
                                                        id="vpn"
                                                        name="surveyAnon"
                                                        value="vpn"
                                                        onClick={onSurveyInputChange}
                                                    />
                                                    {vpnText}
                                                </label>
                                                <label className="radio-btn" htmlFor="tor">
                                                    <input
                                                        type="checkbox"
                                                        id="tor"
                                                        name="surveyAnon"
                                                        value="tor"
                                                        onClick={onSurveyInputChange}
                                                    />
                                                    {torText}
                                                </label>
                                                <label className="radio-btn" htmlFor="proxy">
                                                    <input
                                                        type="checkbox"
                                                        id="proxy"
                                                        name="surveyAnon"
                                                        value="proxy"
                                                        onClick={onSurveyInputChange}
                                                    />
                                                    {proxyText}
                                                </label>
                                                <label className="radio-btn" htmlFor="shared-network">
                                                    <input
                                                        type="checkbox"
                                                        id="shared-network"
                                                        name="surveyAnon"
                                                        value="shared-network"
                                                        onClick={onSurveyInputChange}
                                                    />
                                                    {sharedNetworkText}
                                                </label>
                                            </div>
                                       </div>
                                        <div
                                            className="contact-section"
                                            css={css`
                                                display: flex;
                                                flex-direction: column;
                                                margin: 0 0 1rem;
                                                b {
                                                    font-weight: 600;
                                                }
                                                .text-label {
                                                    max-width: 145px;
                                                    font-size: 16px;
                                                    font-weight: 600;
                                                    margin-right: 5px;
                                                }
                                            `}
                                        >
                                            <label
                                                htmlFor="email"
                                                css={css`
                                                    display: flex;
                                                    justify-content: space-between;
                                                    margin: 0;
                                                    padding: 16px 0;
                                                    border-top: 1px solid ${themeStyles.boxBorderColor};
                                                    
                                                `}
                                            >
                                                <b className="text-label">{emailLabel}</b>
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        flex-direction: column;
                                                        flex: 1;
                                                        max-width: 424px;
                                                        margin-left: auto;
                                                    `}
                                                >
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        css={css`
                                                            flex: 1;
                                                            margin: 0 !important;
                                                            border: 1px solid ${validEmail ? themeStyles.boxBorderColor : '#E23D3D'};
                                                            padding: 0.5rem;
                                                            border-radius: ${themeStyles.borderRadius};
                                                            background: ${themeStyles.secondaryBackgroundColor};
                                                            outline: none;
                                                            max-width: 424px;
                                                            margin-left: auto;
                                                        `}
                                                        onChange={(e) => {
                                                            onEmailInputChange(e)
                                                        }}
                                                    />
                                                    {!validEmail && (
                                                        <div
                                                            css={css`
                                                                font-weight: 400;
                                                                font-size: 12px;
                                                                line-height: 18px;
                                                                color: #E23D3D;
                                                                margin-top: 8px;
                                                            `}
                                                        >
                                                            {invalidEmailText}
                                                        </div>
                                                    )}
                                                </div>
                                            </label>
                                            <label
                                                htmlFor="description"
                                                css={css`
                                                    display: flex;
                                                    justify-content: space-between;
                                                    padding: 16px 0;
                                                    border-top: 1px solid ${themeStyles.boxBorderColor};
                                                    border-bottom: 1px solid ${themeStyles.boxBorderColor};
                                                `}
                                            >
                                                <b className="text-label">{descriptionLabel}</b>
                                                <div
                                                    className="description-input"
                                                    css={css`
                                                        flex: 1;
                                                        min-height: 64px;
                                                        height: auto;
                                                    `}
                                                >
                                                    <textarea
                                                        name="description"
                                                        type="text"
                                                        maxLength={TEXTAREA_MAX_LENGTH}
                                                        css={css`
                                                            /* flex: 1; */
                                                            width: 100%;
                                                            border: 1px solid
                                                                ${showTextAreaMaxLengthError
                                                                    ? 'red'
                                                                    : themeStyles.boxBorderColor};
                                                            padding: 0.5rem;
                                                            border-radius: ${themeStyles.borderRadius};
                                                            background: ${themeStyles.secondaryBackgroundColor};
                                                            min-height: 5rem;
                                                            max-width: 424px;
                                                            margin-left: auto;
                                                            outline: none;
                                                            display: flex;
                                                        `}
                                                        onChange={(e) => {
                                                            const {value} = e.target
                                                            const {length} = value
                                                            if (length >= TEXTAREA_MAX_LENGTH) {
                                                                setShowTextAreaMaxLengthError(true)
                                                            } else {
                                                                setShowTextAreaMaxLengthError(false)
                                                            }
                                                            setDescription(value)
                                                        }}
                                                    />
                                                    {showTextAreaMaxLengthError && (
                                                        <p
                                                            css={css`
                                                                color: red !important;
                                                                font-size: 12px;
                                                                float: right;
                                                                margin: 0 !important;
                                                            `}
                                                        >
                                                            {`max character length: ${TEXTAREA_MAX_LENGTH}`}
                                                        </p>
                                                    )}
                                                </div>
                                            </label>
                                        </div>
                                        <div
                                            className="captcha-section"
                                            css={css`
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;
                                                    margin-bottom: 1rem;
                                                    @media (max-width: 600px) {
                                                        flex-direction: column;
                                                        align-items: flex-start;
                                                        input {
                                                            margin-bottom: 2rem;
                                                        }
                                                    }
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        width: 100%;
                                                        margin: 64px 0;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            font-size: 16px;
                                                            font-weight: 600;
                                                            line-height: 24px;
                                                            max-width: 145px;
                                                        `}
                                                    >
                                                        {captchaLabel}
                                                    </div>
                                                    {captchaData && (
                                                        <img
                                                            alt="captcha"
                                                            src={`${getCaptchaBaseDomain()}/do/captcha?image=${
                                                                captchaData.captcha_image
                                                            }&cmd=gen_image`}
                                                            css={css`
                                                                height: 100%;
                                                                width: 220px;
                                                                margin: auto;
                                                            `}
                                                        />
                                                    )}
                                                    {!captchaData && (
                                                        <img
                                                            alt="loading spinner"
                                                            src="/sp/cdn/images/captcha_loading.gif"
                                                            css={css`
                                                                height: 30px;
                                                                width: 30px;
                                                                margin: auto;
                                                            `}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        width: 100%;
                                                        font-size: 14px;
                                                        line-height: 20px;
                                                        font-weight: 400;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            max-width: 145px;
                                                            margin-right: 5px;
                                                        `}
                                                    >
                                                        {captchaInputLabel}
                                                    </div>
                                                    <div
                                                        css={css`
                                                            max-width: 347px;
                                                            width: 100%;
                                                            margin: auto;
                                                            display: flex;
                                                            flex-direction: column;

                                                            .error-msg {
                                                                font-weight: 400;
                                                                font-size: 12px;
                                                                line-height: 18px;
                                                                color: #E23D3D;
                                                                margin-top: 4px;
                                                            }

                                                            button {
                                                                text-decoration: underline;
                                                                border: 0;
                                                                margin: 0;
                                                                padding: 0;
                                                                background: inherit;
                                                                color: #6677FB;
                                                            }
                                                        `}
                                                    >
                                                        <input
                                                            id="captcha-input"
                                                            type="text"
                                                            onChange={onCaptchaInputChange}
                                                            value={captchaInputText}
                                                            autoComplete="off"
                                                            autoCapitalize="none"
                                                            spellCheck="false"
                                                            aria-label="captcha input"
                                                            aria-haspopup="false"
                                                            disabled={captchaTimerComplete}
                                                            css={css`
                                                                flex: 1;
                                                                border: 1px solid #C3C8DA;
                                                                padding: 0.5rem;
                                                                border-radius: ${themeStyles.borderRadius};
                                                                background: ${themeStyles.secondaryBackgroundColor};
                                                                outline: none;
                                                                max-width: 100%;

                                                                &:disabled {
                                                                    border: 1px solid #E23D3D;
                                                                }
                                                            `}
                                                        />
                                                        {showInvalidCaptchaAttemptMessage && !captchaTimerComplete && (
                                                            <div
                                                                className="error-msg"
                                                                onClick={onReloadClick}
                                                                dangerouslySetInnerHTML={createMarkup(captchaInvalidText)}
                                                            />
                                                        )}
                                                        {captchaTimerComplete && (
                                                            <div
                                                                className="error-msg"
                                                                onClick={onReloadClick}
                                                                dangerouslySetInnerHTML={createMarkup(captchaExpiredText)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        width: auto;
                                                        max-width: 100%;
                                                        margin: 24px auto 24px 0;
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        line-height: 20px;
                                                    `}
                                                >
                                                    {!captchaTimerComplete && (
                                                        <div>
                                                            <span
                                                                css={css`
                                                                    margin-right: 5px;
                                                                `}
                                                            >
                                                                {timeRemainingText}
                                                            </span>
                                                            <b>
                                                            <CountDownTimer
                                                                time={DEFAULT_CAPTCHA_TIME}
                                                                onTimerComplete={() => {
                                                                    setCaptchaTimerComplete(true)
                                                                }}
                                                            />
                                                            </b>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                css={css`
                                                    display: inline-block;
                                                    font-weight: 400;
                                                    font-size: 14px;
                                                    line-height: 20px;

                                                    span {
                                                        margin-right: 5px;
                                                        color: #7F869E;
                                                    }

                                                    .reloadLink {
                                                        color: #6677FB;
                                                        text-decoration: underline;
                                                        
                                                        :hover {
                                                            cursor: pointer;
                                                        }
                                                    }
                                                `}
                                            >
                                                <span>{reloadPrompt1}</span>
                                                <span className="reloadLink" onClick={onReloadClick}>{reloadPrompt2}</span>
                                                <br/>
                                                <span>{reloadPrompt3}</span>
                                            </div>
                                            <hr
                                                css={css`
                                                    border-top: solid 1px #EBECF7;
                                                    border-bottom: 0;
                                                    margin-top: 28px;
                                                `}
                                            />
                                            <Button text={submitButtonText} type="submit" overrideStyles={`
                                                button {
                                                    min-width: 76px;
                                                    height: 32px;
                                                    font-size: 14px;
                                                    line-height: 18px;
                                                    font-weight: 500;
                                                    padding: 0 8px;
                                                    border-radius: 3px;
                                                }
                                            `} isDisabled={!canSubmit}/>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        )}
                </div>
            </section>
        </Layout>
        </>
    )
}

export default CaptchaBlockedPage
