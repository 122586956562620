// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-advanced-search-js": () => import("./../../src/pages/advanced-search.js" /* webpackChunkName: "component---src-pages-advanced-search-js" */),
  "component---src-pages-anonymous-view-js": () => import("./../../src/pages/anonymous-view.js" /* webpackChunkName: "component---src-pages-anonymous-view-js" */),
  "component---src-pages-captcha-block-js": () => import("./../../src/pages/captcha-block.js" /* webpackChunkName: "component---src-pages-captcha-block-js" */),
  "component---src-pages-captcha-js": () => import("./../../src/pages/captcha.js" /* webpackChunkName: "component---src-pages-captcha-js" */),
  "component---src-pages-da-about-us-js": () => import("./../../src/pages/da/about-us.js" /* webpackChunkName: "component---src-pages-da-about-us-js" */),
  "component---src-pages-da-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/da/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-da-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-da-advanced-search-js": () => import("./../../src/pages/da/advanced-search.js" /* webpackChunkName: "component---src-pages-da-advanced-search-js" */),
  "component---src-pages-da-anonymous-view-js": () => import("./../../src/pages/da/anonymous-view.js" /* webpackChunkName: "component---src-pages-da-anonymous-view-js" */),
  "component---src-pages-da-errors-js": () => import("./../../src/pages/da/errors.js" /* webpackChunkName: "component---src-pages-da-errors-js" */),
  "component---src-pages-da-eula-js": () => import("./../../src/pages/da/eula.js" /* webpackChunkName: "component---src-pages-da-eula-js" */),
  "component---src-pages-da-how-startpage-works-js": () => import("./../../src/pages/da/how-startpage-works.js" /* webpackChunkName: "component---src-pages-da-how-startpage-works-js" */),
  "component---src-pages-da-make-homepage-js": () => import("./../../src/pages/da/make-homepage.js" /* webpackChunkName: "component---src-pages-da-make-homepage-js" */),
  "component---src-pages-da-notfound-js": () => import("./../../src/pages/da/notfound.js" /* webpackChunkName: "component---src-pages-da-notfound-js" */),
  "component---src-pages-da-off-line-js": () => import("./../../src/pages/da/off-line.js" /* webpackChunkName: "component---src-pages-da-off-line-js" */),
  "component---src-pages-da-privacy-policy-js": () => import("./../../src/pages/da/privacy-policy.js" /* webpackChunkName: "component---src-pages-da-privacy-policy-js" */),
  "component---src-pages-da-support-privacy-policy-js": () => import("./../../src/pages/da/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-da-support-privacy-policy-js" */),
  "component---src-pages-de-about-us-js": () => import("./../../src/pages/de/about-us.js" /* webpackChunkName: "component---src-pages-de-about-us-js" */),
  "component---src-pages-de-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/de/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-de-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-de-advanced-search-js": () => import("./../../src/pages/de/advanced-search.js" /* webpackChunkName: "component---src-pages-de-advanced-search-js" */),
  "component---src-pages-de-anonymous-view-js": () => import("./../../src/pages/de/anonymous-view.js" /* webpackChunkName: "component---src-pages-de-anonymous-view-js" */),
  "component---src-pages-de-errors-js": () => import("./../../src/pages/de/errors.js" /* webpackChunkName: "component---src-pages-de-errors-js" */),
  "component---src-pages-de-eula-js": () => import("./../../src/pages/de/eula.js" /* webpackChunkName: "component---src-pages-de-eula-js" */),
  "component---src-pages-de-how-startpage-works-js": () => import("./../../src/pages/de/how-startpage-works.js" /* webpackChunkName: "component---src-pages-de-how-startpage-works-js" */),
  "component---src-pages-de-make-homepage-js": () => import("./../../src/pages/de/make-homepage.js" /* webpackChunkName: "component---src-pages-de-make-homepage-js" */),
  "component---src-pages-de-notfound-js": () => import("./../../src/pages/de/notfound.js" /* webpackChunkName: "component---src-pages-de-notfound-js" */),
  "component---src-pages-de-off-line-js": () => import("./../../src/pages/de/off-line.js" /* webpackChunkName: "component---src-pages-de-off-line-js" */),
  "component---src-pages-de-privacy-policy-js": () => import("./../../src/pages/de/privacy-policy.js" /* webpackChunkName: "component---src-pages-de-privacy-policy-js" */),
  "component---src-pages-de-support-privacy-policy-js": () => import("./../../src/pages/de/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-de-support-privacy-policy-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/en/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-en-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-en-advanced-search-js": () => import("./../../src/pages/en/advanced-search.js" /* webpackChunkName: "component---src-pages-en-advanced-search-js" */),
  "component---src-pages-en-anonymous-view-js": () => import("./../../src/pages/en/anonymous-view.js" /* webpackChunkName: "component---src-pages-en-anonymous-view-js" */),
  "component---src-pages-en-errors-js": () => import("./../../src/pages/en/errors.js" /* webpackChunkName: "component---src-pages-en-errors-js" */),
  "component---src-pages-en-eula-js": () => import("./../../src/pages/en/eula.js" /* webpackChunkName: "component---src-pages-en-eula-js" */),
  "component---src-pages-en-how-startpage-works-js": () => import("./../../src/pages/en/how-startpage-works.js" /* webpackChunkName: "component---src-pages-en-how-startpage-works-js" */),
  "component---src-pages-en-make-homepage-js": () => import("./../../src/pages/en/make-homepage.js" /* webpackChunkName: "component---src-pages-en-make-homepage-js" */),
  "component---src-pages-en-notfound-js": () => import("./../../src/pages/en/notfound.js" /* webpackChunkName: "component---src-pages-en-notfound-js" */),
  "component---src-pages-en-off-line-js": () => import("./../../src/pages/en/off-line.js" /* webpackChunkName: "component---src-pages-en-off-line-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-support-privacy-policy-js": () => import("./../../src/pages/en/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-en-support-privacy-policy-js" */),
  "component---src-pages-en-terms-js": () => import("./../../src/pages/en/terms.js" /* webpackChunkName: "component---src-pages-en-terms-js" */),
  "component---src-pages-errors-js": () => import("./../../src/pages/errors.js" /* webpackChunkName: "component---src-pages-errors-js" */),
  "component---src-pages-es-about-us-js": () => import("./../../src/pages/es/about-us.js" /* webpackChunkName: "component---src-pages-es-about-us-js" */),
  "component---src-pages-es-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/es/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-es-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-es-advanced-search-js": () => import("./../../src/pages/es/advanced-search.js" /* webpackChunkName: "component---src-pages-es-advanced-search-js" */),
  "component---src-pages-es-anonymous-view-js": () => import("./../../src/pages/es/anonymous-view.js" /* webpackChunkName: "component---src-pages-es-anonymous-view-js" */),
  "component---src-pages-es-errors-js": () => import("./../../src/pages/es/errors.js" /* webpackChunkName: "component---src-pages-es-errors-js" */),
  "component---src-pages-es-eula-js": () => import("./../../src/pages/es/eula.js" /* webpackChunkName: "component---src-pages-es-eula-js" */),
  "component---src-pages-es-how-startpage-works-js": () => import("./../../src/pages/es/how-startpage-works.js" /* webpackChunkName: "component---src-pages-es-how-startpage-works-js" */),
  "component---src-pages-es-make-homepage-js": () => import("./../../src/pages/es/make-homepage.js" /* webpackChunkName: "component---src-pages-es-make-homepage-js" */),
  "component---src-pages-es-notfound-js": () => import("./../../src/pages/es/notfound.js" /* webpackChunkName: "component---src-pages-es-notfound-js" */),
  "component---src-pages-es-off-line-js": () => import("./../../src/pages/es/off-line.js" /* webpackChunkName: "component---src-pages-es-off-line-js" */),
  "component---src-pages-es-privacy-policy-js": () => import("./../../src/pages/es/privacy-policy.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-js" */),
  "component---src-pages-es-support-privacy-policy-js": () => import("./../../src/pages/es/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-es-support-privacy-policy-js" */),
  "component---src-pages-eula-js": () => import("./../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-feedback-js": () => import("./../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-fr-about-us-js": () => import("./../../src/pages/fr/about-us.js" /* webpackChunkName: "component---src-pages-fr-about-us-js" */),
  "component---src-pages-fr-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/fr/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-fr-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-fr-advanced-search-js": () => import("./../../src/pages/fr/advanced-search.js" /* webpackChunkName: "component---src-pages-fr-advanced-search-js" */),
  "component---src-pages-fr-anonymous-view-js": () => import("./../../src/pages/fr/anonymous-view.js" /* webpackChunkName: "component---src-pages-fr-anonymous-view-js" */),
  "component---src-pages-fr-errors-js": () => import("./../../src/pages/fr/errors.js" /* webpackChunkName: "component---src-pages-fr-errors-js" */),
  "component---src-pages-fr-eula-js": () => import("./../../src/pages/fr/eula.js" /* webpackChunkName: "component---src-pages-fr-eula-js" */),
  "component---src-pages-fr-how-startpage-works-js": () => import("./../../src/pages/fr/how-startpage-works.js" /* webpackChunkName: "component---src-pages-fr-how-startpage-works-js" */),
  "component---src-pages-fr-make-homepage-js": () => import("./../../src/pages/fr/make-homepage.js" /* webpackChunkName: "component---src-pages-fr-make-homepage-js" */),
  "component---src-pages-fr-notfound-js": () => import("./../../src/pages/fr/notfound.js" /* webpackChunkName: "component---src-pages-fr-notfound-js" */),
  "component---src-pages-fr-off-line-js": () => import("./../../src/pages/fr/off-line.js" /* webpackChunkName: "component---src-pages-fr-off-line-js" */),
  "component---src-pages-fr-privacy-policy-js": () => import("./../../src/pages/fr/privacy-policy.js" /* webpackChunkName: "component---src-pages-fr-privacy-policy-js" */),
  "component---src-pages-fr-support-privacy-policy-js": () => import("./../../src/pages/fr/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-fr-support-privacy-policy-js" */),
  "component---src-pages-how-startpage-works-js": () => import("./../../src/pages/how-startpage-works.js" /* webpackChunkName: "component---src-pages-how-startpage-works-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-homepage-js": () => import("./../../src/pages/make-homepage.js" /* webpackChunkName: "component---src-pages-make-homepage-js" */),
  "component---src-pages-nl-about-us-js": () => import("./../../src/pages/nl/about-us.js" /* webpackChunkName: "component---src-pages-nl-about-us-js" */),
  "component---src-pages-nl-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/nl/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-nl-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-nl-advanced-search-js": () => import("./../../src/pages/nl/advanced-search.js" /* webpackChunkName: "component---src-pages-nl-advanced-search-js" */),
  "component---src-pages-nl-anonymous-view-js": () => import("./../../src/pages/nl/anonymous-view.js" /* webpackChunkName: "component---src-pages-nl-anonymous-view-js" */),
  "component---src-pages-nl-errors-js": () => import("./../../src/pages/nl/errors.js" /* webpackChunkName: "component---src-pages-nl-errors-js" */),
  "component---src-pages-nl-eula-js": () => import("./../../src/pages/nl/eula.js" /* webpackChunkName: "component---src-pages-nl-eula-js" */),
  "component---src-pages-nl-how-startpage-works-js": () => import("./../../src/pages/nl/how-startpage-works.js" /* webpackChunkName: "component---src-pages-nl-how-startpage-works-js" */),
  "component---src-pages-nl-make-homepage-js": () => import("./../../src/pages/nl/make-homepage.js" /* webpackChunkName: "component---src-pages-nl-make-homepage-js" */),
  "component---src-pages-nl-notfound-js": () => import("./../../src/pages/nl/notfound.js" /* webpackChunkName: "component---src-pages-nl-notfound-js" */),
  "component---src-pages-nl-off-line-js": () => import("./../../src/pages/nl/off-line.js" /* webpackChunkName: "component---src-pages-nl-off-line-js" */),
  "component---src-pages-nl-privacy-policy-js": () => import("./../../src/pages/nl/privacy-policy.js" /* webpackChunkName: "component---src-pages-nl-privacy-policy-js" */),
  "component---src-pages-nl-support-privacy-policy-js": () => import("./../../src/pages/nl/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-nl-support-privacy-policy-js" */),
  "component---src-pages-no-about-us-js": () => import("./../../src/pages/no/about-us.js" /* webpackChunkName: "component---src-pages-no-about-us-js" */),
  "component---src-pages-no-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/no/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-no-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-no-advanced-search-js": () => import("./../../src/pages/no/advanced-search.js" /* webpackChunkName: "component---src-pages-no-advanced-search-js" */),
  "component---src-pages-no-anonymous-view-js": () => import("./../../src/pages/no/anonymous-view.js" /* webpackChunkName: "component---src-pages-no-anonymous-view-js" */),
  "component---src-pages-no-errors-js": () => import("./../../src/pages/no/errors.js" /* webpackChunkName: "component---src-pages-no-errors-js" */),
  "component---src-pages-no-eula-js": () => import("./../../src/pages/no/eula.js" /* webpackChunkName: "component---src-pages-no-eula-js" */),
  "component---src-pages-no-how-startpage-works-js": () => import("./../../src/pages/no/how-startpage-works.js" /* webpackChunkName: "component---src-pages-no-how-startpage-works-js" */),
  "component---src-pages-no-make-homepage-js": () => import("./../../src/pages/no/make-homepage.js" /* webpackChunkName: "component---src-pages-no-make-homepage-js" */),
  "component---src-pages-no-notfound-js": () => import("./../../src/pages/no/notfound.js" /* webpackChunkName: "component---src-pages-no-notfound-js" */),
  "component---src-pages-no-off-line-js": () => import("./../../src/pages/no/off-line.js" /* webpackChunkName: "component---src-pages-no-off-line-js" */),
  "component---src-pages-no-privacy-policy-js": () => import("./../../src/pages/no/privacy-policy.js" /* webpackChunkName: "component---src-pages-no-privacy-policy-js" */),
  "component---src-pages-no-support-privacy-policy-js": () => import("./../../src/pages/no/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-no-support-privacy-policy-js" */),
  "component---src-pages-notfound-js": () => import("./../../src/pages/notfound.js" /* webpackChunkName: "component---src-pages-notfound-js" */),
  "component---src-pages-off-line-js": () => import("./../../src/pages/off-line.js" /* webpackChunkName: "component---src-pages-off-line-js" */),
  "component---src-pages-pl-about-us-js": () => import("./../../src/pages/pl/about-us.js" /* webpackChunkName: "component---src-pages-pl-about-us-js" */),
  "component---src-pages-pl-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/pl/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-pl-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-pl-advanced-search-js": () => import("./../../src/pages/pl/advanced-search.js" /* webpackChunkName: "component---src-pages-pl-advanced-search-js" */),
  "component---src-pages-pl-anonymous-view-js": () => import("./../../src/pages/pl/anonymous-view.js" /* webpackChunkName: "component---src-pages-pl-anonymous-view-js" */),
  "component---src-pages-pl-errors-js": () => import("./../../src/pages/pl/errors.js" /* webpackChunkName: "component---src-pages-pl-errors-js" */),
  "component---src-pages-pl-eula-js": () => import("./../../src/pages/pl/eula.js" /* webpackChunkName: "component---src-pages-pl-eula-js" */),
  "component---src-pages-pl-how-startpage-works-js": () => import("./../../src/pages/pl/how-startpage-works.js" /* webpackChunkName: "component---src-pages-pl-how-startpage-works-js" */),
  "component---src-pages-pl-make-homepage-js": () => import("./../../src/pages/pl/make-homepage.js" /* webpackChunkName: "component---src-pages-pl-make-homepage-js" */),
  "component---src-pages-pl-notfound-js": () => import("./../../src/pages/pl/notfound.js" /* webpackChunkName: "component---src-pages-pl-notfound-js" */),
  "component---src-pages-pl-off-line-js": () => import("./../../src/pages/pl/off-line.js" /* webpackChunkName: "component---src-pages-pl-off-line-js" */),
  "component---src-pages-pl-privacy-policy-js": () => import("./../../src/pages/pl/privacy-policy.js" /* webpackChunkName: "component---src-pages-pl-privacy-policy-js" */),
  "component---src-pages-pl-support-privacy-policy-js": () => import("./../../src/pages/pl/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-pl-support-privacy-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pt-about-us-js": () => import("./../../src/pages/pt/about-us.js" /* webpackChunkName: "component---src-pages-pt-about-us-js" */),
  "component---src-pages-pt-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/pt/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-pt-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-pt-advanced-search-js": () => import("./../../src/pages/pt/advanced-search.js" /* webpackChunkName: "component---src-pages-pt-advanced-search-js" */),
  "component---src-pages-pt-anonymous-view-js": () => import("./../../src/pages/pt/anonymous-view.js" /* webpackChunkName: "component---src-pages-pt-anonymous-view-js" */),
  "component---src-pages-pt-errors-js": () => import("./../../src/pages/pt/errors.js" /* webpackChunkName: "component---src-pages-pt-errors-js" */),
  "component---src-pages-pt-eula-js": () => import("./../../src/pages/pt/eula.js" /* webpackChunkName: "component---src-pages-pt-eula-js" */),
  "component---src-pages-pt-how-startpage-works-js": () => import("./../../src/pages/pt/how-startpage-works.js" /* webpackChunkName: "component---src-pages-pt-how-startpage-works-js" */),
  "component---src-pages-pt-make-homepage-js": () => import("./../../src/pages/pt/make-homepage.js" /* webpackChunkName: "component---src-pages-pt-make-homepage-js" */),
  "component---src-pages-pt-notfound-js": () => import("./../../src/pages/pt/notfound.js" /* webpackChunkName: "component---src-pages-pt-notfound-js" */),
  "component---src-pages-pt-off-line-js": () => import("./../../src/pages/pt/off-line.js" /* webpackChunkName: "component---src-pages-pt-off-line-js" */),
  "component---src-pages-pt-privacy-policy-js": () => import("./../../src/pages/pt/privacy-policy.js" /* webpackChunkName: "component---src-pages-pt-privacy-policy-js" */),
  "component---src-pages-pt-support-privacy-policy-js": () => import("./../../src/pages/pt/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-pt-support-privacy-policy-js" */),
  "component---src-pages-support-privacy-policy-js": () => import("./../../src/pages/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-support-privacy-policy-js" */),
  "component---src-pages-sv-about-us-js": () => import("./../../src/pages/sv/about-us.js" /* webpackChunkName: "component---src-pages-sv-about-us-js" */),
  "component---src-pages-sv-add-startpage-com-to-your-home-screen-js": () => import("./../../src/pages/sv/add-startpage-com-to-your-home-screen.js" /* webpackChunkName: "component---src-pages-sv-add-startpage-com-to-your-home-screen-js" */),
  "component---src-pages-sv-advanced-search-js": () => import("./../../src/pages/sv/advanced-search.js" /* webpackChunkName: "component---src-pages-sv-advanced-search-js" */),
  "component---src-pages-sv-anonymous-view-js": () => import("./../../src/pages/sv/anonymous-view.js" /* webpackChunkName: "component---src-pages-sv-anonymous-view-js" */),
  "component---src-pages-sv-errors-js": () => import("./../../src/pages/sv/errors.js" /* webpackChunkName: "component---src-pages-sv-errors-js" */),
  "component---src-pages-sv-eula-js": () => import("./../../src/pages/sv/eula.js" /* webpackChunkName: "component---src-pages-sv-eula-js" */),
  "component---src-pages-sv-how-startpage-works-js": () => import("./../../src/pages/sv/how-startpage-works.js" /* webpackChunkName: "component---src-pages-sv-how-startpage-works-js" */),
  "component---src-pages-sv-make-homepage-js": () => import("./../../src/pages/sv/make-homepage.js" /* webpackChunkName: "component---src-pages-sv-make-homepage-js" */),
  "component---src-pages-sv-notfound-js": () => import("./../../src/pages/sv/notfound.js" /* webpackChunkName: "component---src-pages-sv-notfound-js" */),
  "component---src-pages-sv-off-line-js": () => import("./../../src/pages/sv/off-line.js" /* webpackChunkName: "component---src-pages-sv-off-line-js" */),
  "component---src-pages-sv-privacy-policy-js": () => import("./../../src/pages/sv/privacy-policy.js" /* webpackChunkName: "component---src-pages-sv-privacy-policy-js" */),
  "component---src-pages-sv-support-privacy-policy-js": () => import("./../../src/pages/sv/support-privacy-policy.js" /* webpackChunkName: "component---src-pages-sv-support-privacy-policy-js" */)
}

