import axios from 'axios'
import {ENV_BASE_DOMAINS, IS_DEV} from '../env'
import {getUrlParameterByName} from './url'

const captchaSharedTranslations = require('../translations/captchaShared.json')
const captchaDynamicTranslations = require('../translations/captchaDynamic.json')
const captchaBlockedTranslations = require('../translations/captchaBlocked.json')
const captchaFeedbackTranslations = require('../translations/captchaFeedback.json')

const {THIN_PROXY_DEV, PROD: PROD_DOMAIN} = ENV_BASE_DOMAINS

const PRESERVE_LIST = [
    'bds_cq',
    'bds_s',
    'cat',
    'from_mobile',
    'ftst',
    'language',
    'lui',
    'pl',
    'prfe',
    'prfh',
    'query',
    'sc',
    'segment',
    't',
]

export const DEFAULT_CAPTCHA_TIME = 2 // min

export const VPN_LIST = [
    'KeepSolid VPN',
    'Hide My Ass',
    'ExpressVPN',
    'setupvpn.com',
    'DotVPN',
    'uVPN',
    'ZenMate',
    'PureVPN',
    'Hola',
    'CyberGhost',
    'Touch VPN',
    'Red Panda VPN',
    'Windscribe VPN',
    'CroxyProxy',
    'iNinja Proxy',
    'Easy VPN',
    'Opera VPN',
    'Mullvad',
    'ProtonVPN',
    'SurfShark',
    'AirVPN',
    'NordVPN',
    'Free VPN',
    'Browsec VPN',
    'AVG Secure VPN',
    'Avast SecureLine VPN',
    'Avira Phantom VPN',
    'Bitdefender Premium VPN',
    'F-Secure FREEDOME VPN',
    'Kaspersky VPN Secure Connection',
    'McAfee Safe Connect VPN',
    'Norton Secure VPN',
].sort() // makes sure this is sorted alphabetically

export const getCaptchaBaseDomain = () => {
    // return localhost:8080 for local dev
    if (IS_DEV) return THIN_PROXY_DEV
    if (typeof window !== `undefined`) {
        // on static-stage.s1-sp.com (not behind thin_proxy)
        if (window.location.origin.includes('s1-sp')) return PROD_DOMAIN
    }
    // PROD & STAGE (peak2 & eu9 behind thin_proxy), return relative url
    return ``
}

export const getLocalizedCaptchaPageText = (languageCode = `en`, captchaType = `dynamic`) => {
    const translations = captchaType === `dynamic` ? captchaDynamicTranslations : captchaBlockedTranslations
    const localizations = translations[languageCode]
    if (localizations) return localizations
    return translations.en
}

export const getLocalizedCaptchaSharedText = (languageCode = `en`) => {
    const localizations = captchaSharedTranslations[languageCode]
    if (localizations) return localizations
    return captchaSharedTranslations.en
}

export const getLocalizedFeedbackText = (languageCode = `en`) => {
    const localizations = captchaFeedbackTranslations[languageCode]
    if (localizations) return localizations
    return captchaFeedbackTranslations.en
}

export const getCaptchaData = async (bdsEip, bdsToken) => {
    try {
        const form = new FormData()
        form.append('be', bdsEip)
        form.append('bds_t', bdsToken)
        const response = await axios.post(`${getCaptchaBaseDomain()}/do/cp-d`, form, {
            'Content-Type': 'application/x-www-form-urlencoded',
        })
        const {data} = response
        return data
    } catch (e) {
        const {status} = e.response
        const error = new Error(e)
        error.status = status
        throw error
    }
}

export const validateCaptcha = async (
    captchaInputText,
    captchaImageHash,
    captchaReason,
    otherData = {},
) => {
    try {
        const data = {
            cmd: `validate`,
            captcha_text: captchaInputText,
            captcha_reason: captchaReason,
            crypt1: captchaImageHash,
            service: `metasearch`,
        }
        Object.assign(data, otherData)
        for (const k of PRESERVE_LIST) {
            const v = getUrlParameterByName(k)
            if (v) {
                data[k] = v
            }
        }
        const form = new FormData()
        for (const key in data) {
            form.append(key, data[key])
        }
        const response = await axios.post(
            `${getCaptchaBaseDomain()}/do/captcha`,
            form, // data
            {'Content-Type': `application/x-www-form-urlencoded`}, // headers
        )
        const {
            data: {data: redirectUrl, status_type},
        } = response
        return {redirectUrl, status_type}
    } catch (e) {
        console.error(`Error validating captcha data:`, e)
    }
}

export const requestNewCaptcha = async (captchaInputText, captchaImageHash) => {
    try {
        const data = {
            cmd: `new`,
            captcha_text: captchaInputText,
            crypt1: captchaImageHash,
            service: `metasearch`,
        }
        for (const k of PRESERVE_LIST) {
            const v = getUrlParameterByName(k)
            if (v) {
                data[k] = v
            }
        }
        const form = new FormData()
        for (const key in data) {
            form.append(key, data[key])
        }
        const response = await axios.post(
            `${getCaptchaBaseDomain()}/do/captcha`,
            form, // data
            {'Content-Type': `application/x-www-form-urlencoded`}, // headers
        )
        const {
            data: {data: redirectUrl},
        } = response
        return {redirectUrl}
    } catch (e) {
        console.error(`Error validating captcha data:`, e)
    }
}
