import {getUrlParameterByName} from './url'
import {getCookieValue, decodeStartpagePreferences} from './cookie'

export const luiMap = {
    english: 'en',
    dansk: 'da',
    deutsch: 'de',
    espanol: 'es',
    francais: 'fr',
    nederlands: 'nl',
    norsk: 'no',
    polski: 'pl',
    portugues: 'pt',
    svenska: 'sv',
}

const getLanguageCodeFromCookie = () => {
    const preferencesCookie = getCookieValue('preferences')
    if (preferencesCookie) {
        const decodedPreferences = decodeStartpagePreferences(preferencesCookie)
        const languageCode = getUrlParameterByName(
            'lang_homepage',
            `http://some-url.com?${decodedPreferences}`, // make it into a url format for the sake of using getUrlParameterByName
        )
        return languageCode.split('/')[2]
    }
    return null
}

// TODO: add function to extract language code from prfe
// (encded preferences aka short_prefs)

const getLanguageCodeFromUrl = () => {
    const preferencesUrl = getUrlParameterByName('prfh')
    if (preferencesUrl) {
        const decodedPreferences = decodeStartpagePreferences(preferencesUrl)
        const languageCode = getUrlParameterByName(
            'lang_homepage',
            `http://some-url.com?${decodedPreferences}`, // make it into a url format for the sake of using getUrlParameterByName
        )
        return languageCode.split('/')[2]
    }
    const luiVal = getUrlParameterByName('lui')
    if (luiVal && luiMap[luiVal]) {
        return luiMap[luiVal]
    }
    return getUrlParameterByName('lang')
}

const getLanguageCodeFromBrowserPreferences = () => {
    // we need to check for window here, otherwise during build,
    // gatsby will throw "window is undefined" during server-side rendering
    if (typeof window === `undefined`) {
        return null
    }
    const language = window.navigator.userLanguage || window.navigator.language
    const languageCode = language.split('-')[0]
    if (languageCode) return languageCode
    return null
}

export const getLanguageCode = () => {
    const cookiePrefLang = getLanguageCodeFromCookie()
    const urlPrefLang = getLanguageCodeFromUrl()
    const browserPrefLang = getLanguageCodeFromBrowserPreferences()
    // in order of preferred
    if (urlPrefLang) return urlPrefLang
    if (cookiePrefLang) return cookiePrefLang
    if (browserPrefLang) return browserPrefLang
    return `en`
}

export const getLongLanguage = () => Object.keys(luiMap).find((key) => luiMap[key] === getLanguageCode())
