class DummyStorage {
    // eslint-disable-next-line class-methods-use-this
    get length() {
      return 0;
    }
  
    // eslint-disable-next-line class-methods-use-this
    key(index) {
      return null;
    }
  
    // eslint-disable-next-line class-methods-use-this
    getItem(keyName) {
      return null;
    }
  
    // eslint-disable-next-line class-methods-use-this
    setItem(keyName, keyValue) {
      /* do nothing */
    }
  
    // eslint-disable-next-line class-methods-use-this
    removeItem(keyName) {
      /* do nothing */
    }
  
    // eslint-disable-next-line class-methods-use-this
    clear() {
      /* do nothing */
    }
  }
  
  // NOTE: if cookies are *completely turned off* then accessing localStorage
  // throws a security error, making it very dangerous to code directly against
  // this API; instead wrap `window.localStorage` around a dummy accessor.
  // NOTE: *also* turns out Firefox lets you turn off dom.storage in which
  // case it won't throw but it will return null so also guard against that.
  const initLocalStorage = () => {
    let store = null;
    if (typeof window !== `undefined`) {
      try {
        store = window.localStorage;
        if (!store) {
          // eslint-disable-next-line no-console
          console.warn("Local storage is null");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn("Local storage not available: %s", err);
      }
    }
    return store ?? new DummyStorage();
  };
  
  export const localStore = initLocalStorage();
  